import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18next";
import { getCookie, setCookie } from "../../utils/cookie";

type Language = {
    lang: string;
    toggleLang: () => void;
};

const localesMap = Object.fromEntries(
    Object.entries(import.meta.glob('/public/locales/**/*.json')).map(
        ([path, loadLocale]) => [path.match(/(?<=\/public\/locales\/)[^\/]+(?=\/)/), loadLocale],
    ),
) as Record<string, () => Promise<{ default: Record<string, string> }>>;
const availableLocales = Object.keys(localesMap);

export const languageStore = createSlice({
    name: "language",
    initialState: {
        lang: getCookie("lang") || i18n.language,
        locales: availableLocales,
    },
    reducers: {
        toggleLang: (state, action) => {
            const locales = availableLocales;
            const locale = i18n.language;
            const changedLocale = action.payload || locale;
            if (locale !== changedLocale) i18n.changeLanguage(changedLocale);
            state.lang = changedLocale;
            // i18n.changeLanguage(
            //     locales[(locales.indexOf(locale) + 1) % locales.length],
            // );
            // state.lang = locales[(locales.indexOf(locale) + 1) % locales.length];
            setCookie("lang", changedLocale, 30);
        },
    },
});

export const { toggleLang } = languageStore.actions;

export default languageStore.reducer;