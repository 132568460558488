import { createSlice } from "@reduxjs/toolkit";
import { getCookie, setCookie } from "../../utils/cookie";

export const themeStore = createSlice({
  name: "counter",
  initialState: {
    useDark: getCookie("dark") === "true" ? true : false,
  },
  reducers: {
    toggleDarkMode: (state, action) => {
      state.useDark = action.payload ?? !state.useDark;
      setCookie("dark", state.useDark.toString(), 30);
    },
  },
});

export const { toggleDarkMode } = themeStore.actions;

export default themeStore.reducer;
